import classes from './ComingSoon.module.scss'
import Socials from '../../components/Social/Social'
import logo from '../../assets/images/bumzLogo.png'
import bake from '../../assets/images/csMuffin.png'
import blend from '../../assets/images/csSmoothie.png'
import brew from '../../assets/images/csLatte.png'
import menuDrinks from '../../assets/images/drinkMenu.png'
import menuFood from '../../assets/images/foodMenu.png'
import fullMenu from '../../BeachBumzMenu.pdf'
// import { Wave } from '../../components/Shared/Shapes'

const imageSlider = (
  <div
    className={[classes.ukSlideshow].join(' ')}
    tabIndex={-1}
    data-uk-slideshow="animation:fade; autoplay:true; draggable:false; ratio:1:1; pause-on-hover:false; autoplay-interval:3000;"
  >
    <ul className={[classes.ukSlides, "uk-slideshow-items"].join(' ')}>
      <li><img src={brew} alt="" /></li>
      <li><img src={bake} alt="" /></li>
      <li><img src={blend} alt="" /></li>
    </ul>
  </div>
)

const textSlider = (
  <h2 className={[classes.mainHead].join(' ')}>
    <div
      className={[classes.ukTextSlide].join(' ')}
      tabIndex={-1}
      data-uk-slideshow="animation:fade; autoplay:true; draggable:false; ratio:false; pause-on-hover:false; autoplay-interval:3000;"
    >
      <span>Good things are&nbsp;</span>
      {/* Good things are&nbsp; */}
      <ul className={[classes.ukSlides, "uk-slideshow-items"].join(' ')}>
        <li className={classes.one}> brewing...</li>
        <li className={classes.two}> baking...</li>
        <li className={classes.three}> blending...</li>
      </ul>
    </div>
  </h2>
)
export default function Home() {
  return (
    <div className={[classes.pageStyles, classes.ComingSoon].join(' ')}>
      <div className={[classes.fullSec, classes.treatSliderBtmText].join(' ')}>
        <div className={[classes.fullSec, classes.bgWrap].join(' ')}>
          <div className={classes.hd1}><img src={logo} alt="Coffee Shop in Gulf Shores, AL - Beach Bumz" /></div>

          <div className={classes.insideTw}>
            <div className={classes.content}>
              <div className={classes.text}>
                <h1 className={[classes.newBigHead].join(' ')}>Coming Soon</h1>
                <div className={classes.socBox}>
                  <Socials />
                </div>
                {textSlider}
              </div>

              {imageSlider}
            </div>

            <div className={classes.mobImgRow}>
              <div className={classes.inside}>
                <img src={brew} alt="" />
                <img src={bake} alt="" />
                <img src={blend} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullSec, classes.intro].join(' ')}>
        <div className={[classes.sectionWrap, classes.medDef].join(' ')}>
          <h2>Beach Bumz Cafe</h2>
          <h3>Your Relaxation Destination</h3>
          <p>
            Nestled within the Alabama Gulf Coast Sports Center, Beach Bumz Cafe offers a relaxed atmosphere, perfect for 
            unwinding or catching up with friends. Enjoy cozy indoor seating or take in the fresh air in our covered courtyard.
          </p>
          <p>
            Our menu features all your favorite coffee options, from classic espressos to indulgent lattes, alongside 
            a healthy selection of delicious salads, wraps, protein shakes, and more. Whether you're fueling up before a 
            workout, grabbing a quick bite, or simply savoring a moment of calm, Beach Bumz Cafe is the perfect spot to recharge.
          </p>
        </div>
      </div>
      <div className={[classes.fullSec, classes.menus].join(' ')}>
        <div className={[classes.sectionWrap, classes.insideTw].join(' ')}>
          <h3>Our Menu</h3>
          <p>Select a menu below to view larger version</p>
          <div className={[classes.flexRow, classes.jc_around, classes.flex2].join(' ')}>
            <a href={fullMenu} target="_blank" rel="noreferrer" className={[classes.flexItem, classes.i1].join(' ')}><img src={menuDrinks} alt="Beach Bumz drink menu" /></a>
            <a href={fullMenu} target="_blank" rel="noreferrer" className={[classes.flexItem, classes.i2].join(' ')}><img src={menuFood} alt="Beach Bumz food menu" /></a>
          </div>
        </div>
      </div>
    </div>
  )
}